<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>
		

		<div v-if="!loadingData">


            <a-row :gutter="24">
                <a-col :span="24"  :md="24" :lg="24" :xl="24" class="mt-12" style="margin-top: 30px;">
                    <a-row :gutter="24">

                        <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
                            
                            <WidgetCounter
                                title="Users"
                                :value="institutionStats.totalUsers"
                                prefix=""
                                suffix=""
                                :icon="userIcon"
                            ></WidgetCounter>
                        </a-col>

                        <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
                            <WidgetCounter
                                title="Admins"
                                :value="institutionStats.adminNum"
                                prefix=""
                                suffix=""
                                :icon="userIcon"
                            ></WidgetCounter>
                        </a-col>

                        <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
                            <WidgetCounter
                                title="Teachers"
                                :value="institutionStats.teacherNum"
                                prefix=""
                                suffix=""
                                :icon="userIcon"
                            ></WidgetCounter>
                        </a-col>

                        <a-col :span="24" :md="6" :lg="6" :xl="6" class="mb-24">
                            <WidgetCounter
                                title="Students"
                                :value="institutionStats.studentNum"
                                prefix=""
                                suffix=""
                                :icon="userIcon"
                            ></WidgetCounter>
                        </a-col>
                        <!-- {{ statistics }} -->
                    </a-row>

                </a-col>
            </a-row>
			

		</div>

		<div v-if="loadingData">

			<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 80vh">

				<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

					<a-spin size="large" />

				</a-col>

			</a-row>
		</div>
	</div>
</template>

<script>
	import Papa from 'papaparse';
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../components/Widgets/WidgetCounter' ;

	const tableColumns = [
		{
			title: 'Icon',
			scopedSlots: { customRender: 'icon' },
			class: '',
		},
		{
			title: 'Name',
			scopedSlots: { customRender: 'name' },
			class: 'font-bold',
			width: 250,
		},
		{
			title: 'Slots',
			scopedSlots: { customRender: 'slots' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Joining Fee',
			scopedSlots: { customRender: 'payment' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Creator',
			scopedSlots: { customRender: 'creator' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Created At',
			scopedSlots: { customRender: 'createdAt' },
			class: 'text-muted text-sm',
		},
		{
			title: 'Action',
			align: 'center',
			scopedSlots: { customRender: 'action' },
			class: 'text-center',
		},
	];

    const userIcon = `
			<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z" fill="#111827"/>
				<path d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z" fill="#111827"/>
				<path d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z" fill="#111827"/>
				<path d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z" fill="#111827"/>
			</svg>
		`

	export default ({

		
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {

				loadingData: false,

				classes: [],
				currentPage: 1,
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },
                userIcon,

				tableColumns,
				file: '',
				content: [],
				parsed: false,

                institutionStats: {
                    classNum: 0,
					totalUsers: 0,
                    adminNum: 0,
                    teacherNum: 0,
                    studentNum: 0,
                }
				
      		}
		},
		created() {
			this.getInstituteStats()
		},
		methods: {

			async onViewClass(uuid) {
				
				this.$router.push(`/class-management/view/${uuid}`);
			},

			handleFileUpload(event){
				this.file = event.target.files[0];
				this.parseFile();
			},

			parseFile(){
				Papa.parse( this.file, {
					header: true,
					skipEmptyLines: true,
					complete: function( results ){
						this.content = results;
						this.parsed = true;
					}.bind(this)
				} );
			},

            async getInstituteStats() {

				this.loadingData = true;

				let token = await localStorage.getItem("user_token")
				let user_details = JSON.parse(await localStorage.getItem("user_details"))
				let institution_details = JSON.parse(await localStorage.getItem("institution_details"))

                const { currentPage, perPage } = this.pagination;

                let url = `${this.$BACKEND_URL}/educational-institution-reports/${institution_details.uuid}`;

                const config = {
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${token}`,
					},
				};

                this.$AXIOS.get(url, config).then(async(response) => {
                    if (response.status == 200) {
						this.institutionStats.classNum = response.data.classNum;
						this.institutionStats.totalUsers = response.data.adminNum + response.data.teacherNum + response.data.studentNum;
						this.institutionStats.adminNum = response.data.adminNum;
						this.institutionStats.teacherNum = response.data.teacherNum;
						this.institutionStats.studentNum = response.data.studentNum;
                    }
					
					this.loadingData = false;
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a{
    color: #FFFFFF !important;
	background-color: #734EBF;
}

a:color{
    color: #734EBF !important;
}

#classImg {
	border-radius: 10px;
}

a:hover {
    color: inherit !important;
}

#viewBtn, #viewBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #734EBF;
}
</style>